import React, { useState } from 'react';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import pic1 from '../assets/images/provenance-book.jpg';
import domPic from '../assets/images/dom.jpg';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const IndexPage = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [isRobot, setIsRobot] = useState(true);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [mailingListEmail, setMailingListEmail] = useState();
  const [mailingListEmailError, setMailingListEmailError] = useState(false);
  const [mailingListStatus, setMailingListStatus] = useState();
  const handleMailingListSubmit = async e => {
    e.preventDefault();
    setMailingListEmailError(false);
    if (
      !mailingListEmail ||
      mailingListEmail === '' ||
      mailingListEmail.length > 100
    ) {
      setMailingListEmailError(true);
    } else {
      const result = await addToMailchimp(mailingListEmail);
      setMailingListStatus(result.msg);
    }
  };

  const onFormSubmit = async e => {
    e.preventDefault();

    setNameError(false);
    setEmailError(false);
    setMessageError(false);
    setMessageSent(false);
    setSendError(false);

    let hasError = false;
    if (!name || name === '' || name.length > 100) {
      setNameError(true);
      hasError = true;
    }
    if (!email || email === '' || email.length > 100) {
      setEmailError(true);
      hasError = true;
    }

    if (
      !message ||
      message === '' ||
      message.length < 5 ||
      message.length > 5000
    ) {
      setMessageError(true);
      hasError = true;
    }
    if (isRobot) {
      hasError = true;
    }

    if (hasError) {
      return;
    }

    try {
      await window.fetch('https://mailthis.to/dominicmwilliams', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          name: name,
          _subject: 'Message via dominicmwilliams.com',
          message: message,
        }),
      });
      //const json = await response.json();
      window.location.href = 'https://mailthis.to/confirm';
      // if (!json) {
      //   setSendError(true);
      // } else {
      //   setMessageSent(true);
      // }
    } catch (err) {
      setMessageSent(false);
      setSendError(true);
    }
  };

  return (
    <Layout>
      <section id="banner">
        <div className="inner">
          <h2>Dominic M. Williams</h2>
          <p>Fantasy Author</p>
        </div>
        <Scroll type="id" element="one">
          <a href="#one" className="button learn more">
            Learn more
          </a>
        </Scroll>
      </section>

      <section id="one" className="wrapper style3 special">
        <div className="inner">
          <header className="major">
            <h2>About Dominic</h2>
            <img className="domPhoto" src={domPic} alt="Dominic Williams" />
            <p>
              Dominic is a jack of all trades, and definitely a master of none.
              His eclectic career so far has taken him from presenting
              international conferences and events, to interviewing CEOs of the
              largest companies in the world. He now helps partners of a Big
              Four professional services firm make more money by encouraging
              them to speak and write like human beings.
            </p>
            <p>
              His wife has tolerated his foray into creative writing so far, and
              their Shiba Inu has enjoyed the “thinking walks.”
            </p>
            <p>
              Provenance is Dominic’s first novel, which he describes as "a
              ruthlessly thrown together collection of words that resemble a
              story”. He takes inspiration from his travels and his love of
              history, geography, and human (mis)behaviour.
            </p>
            <p>
              Dominic's claim to fame is to visit international Irish pubs in
              every country he travels to, some of which are pretty quirky – the
              highest (Cusco, Peru), the driest (Doha, Qatar), and the least
              Irish (Istanbul, Turkey). He still claims that he gave the
              Peruvian pub its tagline of “Top of the mountain to ‘ya.” This is
              unconfirmed.
            </p>
            <p>
              He tends not to win awards or prizes but could be considered above
              average at poker and racketlons.
            </p>
          </header>
          {/* <ul className="icons major">
          <li>
            <span className="icon fa-gem major style1">
              <span className="label">Lorem</span>
            </span>
          </li>
          <li>
            <span className="icon fa-heart major style2">
              <span className="label">Ipsum</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-code major style3">
              <span className="label">Dolor</span>
            </span>
          </li>
        </ul> */}
        </div>
      </section>

      <section id="two" className="wrapper alt style2">
        <section className="spotlight">
          <div className="image">
            <img src={pic1} alt="" />
          </div>
          <div className="content">
            <h2>Provenance</h2>
            <p>
              Provenance is Dominic’s first novel. It’s a story of journey and
              discovery, as two very different societies collide.
            </p>
            <a href="/provenance" className="button fit secondary">
              Learn more
            </a>
            <a
              href="https://www.amazon.co.uk/Provenance-Katari-Chronicles-Book-1-ebook/dp/B08BLVBST4"
              className="button fit primary"
            >
              Buy now
            </a>
          </div>
        </section>
        {/* <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>
            Tortor dolore feugiat
            <br />
            elementum magna
          </h2>
          <p>
            Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
            imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
          </p>
        </div>
      </section> */}
      </section>

      <section id="three" className="wrapper style6 special">
        <div className="inner">
          <ul className="features">
            <li className="mailingListSignup">
              <h3>Subscribe to the mailing list</h3>
              <form onSubmit={handleMailingListSubmit}>
                <input
                  type="email"
                  onChange={e => setMailingListEmail(e.target.value)}
                  placeholder="My email address"
                  name="email"
                />
                {mailingListEmailError && <p>Your email address is invalid</p>}
                <br />
                <button
                  type="submit"
                  value="Subscribe"
                  className="button  primary"
                >
                  Subscribe
                </button>
                {mailingListStatus && <p>{mailingListStatus}</p>}
              </form>
            </li>
          </ul>
        </div>
      </section>

      <section className="wrapper style2 ">
        <h4 className="formContactTitle">Contact Dominic</h4>
        <form method="post" action="#" onSubmit={onFormSubmit}>
          <div className="row gtr-uniform">
            <div className="col-6 col-12-xsmall">
              <input
                type="text"
                name="name"
                id="name"
                defaultValue=""
                placeholder="Name"
                onChange={e => setName(e.target.value)}
              />
              {nameError && <p>Your name is invalid</p>}
            </div>
            <div className="col-6 col-12-xsmall">
              <input
                type="email"
                name="email"
                id="email"
                defaultValue=""
                placeholder="Email"
                onChange={e => setEmail(e.target.value)}
              />
              {emailError && <p>Your email address is invalid</p>}
            </div>
            <div className="col-12">
              <textarea
                name="message"
                id="message"
                placeholder="Enter your message"
                rows="6"
                onChange={e => setMessage(e.target.value)}
              ></textarea>
              {messageError && <p>Your message is invalid</p>}
            </div>
            <div className="col-6 col-12-small">
              <input
                type="checkbox"
                id="human"
                name="human"
                onChange={e => {
                  setIsRobot(!isRobot);
                }}
                checked={!isRobot}
              />
              <label htmlFor="human">I am not a robot</label>
            </div>
            <div className="col-12">
              <ul className="actions">
                <li>
                  <input
                    type="submit"
                    value="Send Message"
                    className="primary"
                  />
                  {sendError && (
                    <p>
                      <br />
                      There was an error when sending the message. Please try
                      again.
                    </p>
                  )}
                  {messageSent && (
                    <p>
                      <br />
                      Thanks, I'll be in touch shortly.
                    </p>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </form>
      </section>

      {/* <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Arcue ut vel commodo</h2>
          <p>
            Aliquam ut ex ut augue consectetur interdum endrerit imperdiet amet
            eleifend fringilla.
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="/#" className="button fit primary">
              Activate
            </a>
          </li>
          <li>
            <a href="/#" className="button fit">
              Learn More
            </a>
          </li>
        </ul>
      </div>
    </section> */}
    </Layout>
  );
};
export default IndexPage;
